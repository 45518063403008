<template>
  <div class="PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('wallet.default[4]')"
            left-arrow
            @click-left="$router.go(-1)"
    >
    </van-nav-bar>
    <div class="bgc">
    </div>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="mytable">
        <table>


        </table>
      </div>
    </van-list>
  </div>

</template>

<script>
  export default {
    name: 'Wallet',
    components: {

    },
    props: ['walletType','id'],
    data() {
      return {
        active:0,
        loading: false,//显示正在加载
        finished: false,//控制是否禁止触发上拉触底的事件
        backDate: JSON.parse(localStorage['robotHistoryList'])||[],
        pages:1,
        pagesize:20,
        robotUserName:localStorage['robotUserName']||''

      }
    },

    created() {
      this.active = 0;
    },
    mounted() {
      // this.getRobotHistory(this.id)

    },
    methods: {
      //触底触发，页面初始化触发
      onLoad() {
        // 异步更新数据
        this.getRobotHistory(this.id)
      },
      //获取用户历史记录
      getRobotHistory(type){
        let json={
          user_id:type,
          page:this.pages,
          limit:this.pagesize,
        }
        this.pages++
        this.$Model.RobotHistory(json,(data)=>{
          if(this.pages==2){
            this.backDate=data.data.data
            console.log(this.backDate.length)
          }else{
            this.backDate=[...this.backDate,...data.data.data]
          }
          console.log()
          // 加载状态结束，触底会自动开启正在 加载
          this.loading = false;
          // 数据全部加载完成
          if (this.backDate.length >= data.data.total) {
            //上拉触底将不在触发
            this.finished = true;
          }
        })
      },
      onClickRight(){
        this.$router.push("/user/withdrawalCenter");
      },
    },
  }
</script>

<style scoped>
  /* .van-nav-bar--fixed,.Site .van-nav-bar{
  background-color: #0f25fe;
} */
  .PageBox {
    overflow: auto;
    padding-top: 40px;
    background-color: #FFFAEF;
  }

  .PageBox .bgc {
    width: 100%;
    background-color: #FFFAEF;
    height: 46px;
    position: fixed;
    top: 0;
    z-index: 200;
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#000;
    font-weight: bold;
  }
  .topTitle {
    position: absolute;
    max-width: 70%;
    white-space: nowrap;
    font-size: 18px;
    color: #fff;
    background-color: transparent;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  .PageBox>>>.mytable {
    background-color: #FFFFFF;
    width: 91%;
    margin: 0 auto;
    margin-top: 26px;
  }

  .PageBox>>>table {
    width: 100%;
    border: none;
    /* border-spacing: 1px; */
    border-collapse: collapse;
  }

  .PageBox>>>th:nth-child(1) {
    background-color: #b3b5fc;
    font-weight: 600;
    font-size: 13px;
    /* padding: 6px 0px; */
    border: none;
    height: 33px;
    width: 26%;
  }

  .PageBox>>>th:nth-child(2) {
    background-color: #b3b5fc;
    font-weight: 600;
    font-size: 13px;
    /* padding: 6px 0px; */
    border: none;
    height: 33px;
    width: 24%px;
  }

  .PageBox>>>th:nth-child(3) {
    background-color: #b3b5fc;
    font-weight: 600;
    font-size: 13px;
    /* padding: 6px 0px; */
    border: none;
    height: 33px;
    width: 24%;
  }

  .PageBox>>>th:nth-child(4) {
    background-color: #b3b5fc;
    font-weight: 600;
    font-size: 13px;
    /* padding: 6px 0px; */
    border: none;
    height: 33px;
    width: 26%;
  }

  .PageBox>>>th {
    border-right: 1px solid #FFFFFF !important;
  }

  .PageBox>>>table tr td {
    font-size: 11px;
    color: #626262;
    padding: 8px 0px;
    text-align: center;
    border: none;
    border-right: 1px solid #FFFFFF !important;
  }

  table tbody tr:nth-child(odd) {
    background: #eff0f2;
  }

  table tbody tr:nth-child(even) {
    background: #e1e1f9;
  }

</style>
